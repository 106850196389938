import { useContext } from "react";
import { useSelector } from "react-redux";

// MUI
import { Stack } from "@mui/material";

// Icons
import { ArrowsCounterClockwise, Gear, Pause, Play } from "@phosphor-icons/react";

// Context
import { timerContext } from "./Timer";

function TimeSide() {
    // Redux
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)

    // Context
    const {
        time, 
        setTime, 
        workDuration, 
        breakDuration, 
        isActive,
        setIsActive,
        toggleStartPause, 
        isWorkSession, 
        setIsWorkSession, 
        resetTimer, 
        setSide
    } = useContext(timerContext)

    // Functions
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? "0" : ""}${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };

    const switchSession = () => {
        if (isWorkSession) {
            setIsActive(false)
            setTime(breakDuration)
            setIsWorkSession(false)
        } else {
            setIsActive(false)
            setTime(workDuration)
            setIsWorkSession(true)
        }
    }

    return (
        <>
            <Stack 
                fontSize="96px" fontWeight="700"
                lineHeight="1"
            >
                {formatTime(time)}
            </Stack>
            <Stack
                direction="row" alignItems="center"
                justifyContent="space-between" width="245px"
            >
                <Stack
                    direction="row" gap="15px"
                >
                    <Stack
                        sx={{
                            cursor: "pointer"
                        }}
                        onClick={toggleStartPause}
                    >
                        {isActive ? <Pause size={24} /> : <Play size={24} />}
                    </Stack>
                    <Stack
                        sx={{
                            cursor: "pointer"
                        }}
                        onClick={resetTimer}
                    >
                        <ArrowsCounterClockwise size={24} />
                    </Stack>
                    <Stack
                        sx={{
                            cursor: "pointer"
                        }}
                        onClick={() => setSide("setting-timer")}
                    >
                        <Gear size={24} />
                    </Stack>
                </Stack>
                <Stack
                    width="70px" height="25px"
                    direction="row" alignItems="center"
                    justifyContent="center" 
                    fontSize="16px" fontWeight="700"
                    border="1px solid" borderRadius="1000px"
                    borderColor={isDarkMode ? "#fff" : "#020202"}
                    sx={{
                        cursor: "pointer"
                    }}
                    onClick={switchSession}
                >
                    {isWorkSession ? "break" : "focus"}
                </Stack>
            </Stack>
        </>
    );
}

export default TimeSide;