import { useSelector, useDispatch } from "react-redux";
import { addNote, setIsQuotesOpen, setIsTasksOpen } from "../../../Redux/toolsSlice";

// Libraries
import { v4 as uuidv4 } from 'uuid';

// MUI & Style
import { useStyle } from "../../../Atoms/Style";
import { Stack } from "@mui/material";

// Icons
import { Calculator, Headphones, ListBullets, NotePencil, Quotes } from "@phosphor-icons/react";

// Constants
import { tools } from "../../../Constants";

function Tools() {
    // Redux
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const isTasksOpen = useSelector((state) => state.tools.isTasksOpen)
    const isQuotesOpen = useSelector((state) => state.tools.isQuotesOpen)
    const dispatch = useDispatch()

    // Style
    const style = useStyle(isDarkMode);

    // Functions
    const renderIcon = (tool) => {
        switch (tool) {
            case "Tasks":
                return <ListBullets size={24} />
            case "Music":
                return <Headphones size={24} />
            case "Notes":
                return <NotePencil size={24} />
            case "Quotes":
                return <Quotes size={24} />
            case "Materials":
                return <Calculator size={24} />
            default:
                break;
        }
    }

    const clickHandler = (tool) => {
        switch (tool) {
            case "Tasks":
                dispatch(setIsTasksOpen(!isTasksOpen))
                break;
            case "Music":
                console.log("Music")
                break;
            case "Notes":
                dispatch(addNote(uuidv4()))
                break;
            case "Quotes":
                dispatch(setIsQuotesOpen(!isQuotesOpen))
                break;
            case "Materials":
                console.log("Material")
                break;
            default:
                break;
        }
    }

    return (
        <Stack
            width="100%" height="38.5%"
            direction="column" justifyContent="space-between"
            className={style.card}
        >
            {tools.map(item => {
                return (
                    <Stack
                        key={uuidv4()}
                        width="100%" height="50px"
                        border="1px solid"
                        borderColor={isDarkMode ? "#fff" : "#020202"}
                        borderRadius="1000px"
                        direction="row" alignItems="center"
                        justifyContent="center"
                        fontSize="18px" fontWeight="600"
                        position="relative"
                        sx={{
                            cursor: "pointer"
                        }}
                        onClick={() => clickHandler(item)}
                    >
                        <Stack position="absolute" left="15px">{renderIcon(item)}</Stack>
                        {item}
                        {(item === "Music" || item === "Materials") &&
                            <Stack
                                width="100%" height="100%"
                                position="absolute" display="flex"
                                justifyContent="center" alignItems="center"
                                borderRadius="1000px"
                                sx={{
                                    opacity: "0",
                                    ":hover": {
                                        background: isDarkMode ? "#020202" : "#fff",
                                        opacity: "1"
                                    }
                                }}
                            >
                                Coming Soon!
                            </Stack>
                        }
                    </Stack>
                )
            })}
        </Stack>
    );
}

export default Tools;