import { configureStore } from '@reduxjs/toolkit';

import userSlice from "./userSlice"
import vibeSlice from './vibeSlice';
import toolsSlice from './toolsSlice';

export const store = configureStore({
    reducer: {
        user: userSlice,
        vibe: vibeSlice,
        tools: toolsSlice,
    },
});