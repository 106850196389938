import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useRef } from 'react';
import { useSelector } from "react-redux";

// MUI
import { Stack } from "@mui/material";

// Component
import Dashboard from './Components/Dashboard/Dashboard';
import SignUp from './Components/SignUp/SignUp';
import SignIn from './Components/SignIn/SignIn';

// Constants
import { PATH } from "./Constants/path";

function App() {
  const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
  const isBackgroundImage = useSelector((state) => state.vibe.isBackgroundImage)
  const background = useSelector((state) => state.vibe.background)
  const elementRef = useRef(null)

  return (
    <Stack
      width="100vw" height="100vh"
      color={isDarkMode ? "#fff" : "#020202"}
      sx={
        isBackgroundImage ?
          {
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover', backgroundPosition: "center"
          }
        :
          {
            background: background,
          }
      }
      ref={elementRef}
    >
      <BrowserRouter>
        <Routes>
          <Route path={PATH.SIGN_UP} element={<SignUp />} />
          <Route path={PATH.SIGN_IN} element={<SignIn />} />
          <Route path={PATH.DASHBOARD} element={<Dashboard elementRef={elementRef} />} />
          <Route path="*" element={<Navigate to={PATH.DASHBOARD} />} />
        </Routes>
      </BrowserRouter>
    </Stack>
  );
}

export default App;
