import { useState } from "react";
import { useSelector } from "react-redux";

// Libraries
import emailjs from 'emailjs-com';

// MUI & Style
import { useStyle } from "../../../Atoms/Style";
import { Stack } from "@mui/material";

// Icons
import { X } from "@phosphor-icons/react";

// Constants
import { numbers } from "../../../Constants";

function Feedback() {
    // Redux & useState
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const [popUp, setPopUp] = useState(false)
    const [rating, setRating] = useState(1)
    const [experience, setExperience] = useState("")
    const [email, setEmail] = useState("")

    // Style
    const style = useStyle()

    // Functions
    const sendEmail = () => {    
        if (rating && experience) {
            emailjs.send(
                'service_je06rzh',
                'template_fkhpsvt',
                {
                    name: email,
                    rating: rating,
                    message: experience,
                },             
                'noMb6cxteBWVygOur'
            )
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

            setRating(null)
            setExperience("")
            setEmail("")
        }
    };

    const clickHandler = () => {
        setPopUp(!popUp)
    }

    return (
        <>
            {popUp &&
                <Stack
                    position="absolute" top="0" left="0"
                    width="100dvw" height="100dvh"
                    direction="row" alignItems="center"
                    justifyContent="center"
                >
                    <Stack 
                        position="absolute" zIndex="3" 
                        width="100%" height="100%"
                        sx={{
                            background: "rgba(0, 0, 0, 0.5)"
                        }}
                        onClick={clickHandler}
                    ></Stack>
                    <Stack
                        width="300px" height="fit-content"
                        padding="20px" borderRadius="10px"
                        direction="column" alignItems="center"
                        gap="10px" zIndex="4" position="relative"
                        sx={{
                            background: "#fff",
                            color: "#020202"
                        }}
                    >
                        <Stack
                            fontSize="24px" fontWeight="600"
                        >
                            feedback
                        </Stack>
                        <Stack
                            direction="row" alignItems="center"
                            justifyContent="center" gap="10px"
                        >
                            {numbers.map(item => (
                                <Stack 
                                    width="30px" height="30px"
                                    border="2px solid" color={rating === item ? "#ffbe0b" : "#020202"}
                                    borderRadius="10px" borderColor={rating === item ? "#ffbe0b" : "#020202"}
                                    direction="row" alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setRating(item)}
                                >
                                    {item}
                                </Stack>
                            ))}
                        </Stack>
                        <input className={`${style.input} ${style.feedback}`} value={experience} onChange={(item) => setExperience(item.target.value)} placeholder="Your experience / Report Bug" />
                        <input className={`${style.input} ${style.feedback}`} value={email} onChange={(item) => setEmail(item.target.value)} placeholder="E-mail (Optional)" />
                        <Stack>
                            <button className={`${style.sendFeedback}`} onClick={sendEmail}>Send Feedback</button>
                        </Stack>
                        <Stack 
                            position="absolute" top="10px" right="10px"
                            onClick={clickHandler}
                        >
                            <X size={18} cursor="pointer" />
                        </Stack>
                    </Stack>
                </Stack>
            }
            <Stack
                position="fixed" top="75%" right="-32px"
                fontSize="18px" fontWeight="500"
                padding="10px" borderRadius="10px 10px 0px 0px"
                sx={{
                    background: isDarkMode ? "#020202" : "#fff", cursor: "pointer",
                    transform: "rotate(-90deg)",
                    transformOrigin: "center"
                }}
                onClick={clickHandler}
            >
                feedback
            </Stack>
        </>
    )
}

export default Feedback;