import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        isLoggedIn: false,
        isPerformanceOpen: false,
    },
    reducers: {
        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        },
        setIsPerformanceOpen: (state, action) => {
            state.isPerformanceOpen = action.payload
        }
    },
});

export const { setIsLoggedIn, setIsPerformanceOpen } = userSlice.actions;
export default userSlice.reducer;