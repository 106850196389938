import { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

// MUI & Style
import { useStyle } from "../../../Atoms/Style";
import { Stack } from "@mui/material";

// Icons
import { Books, Brain, Coffee } from "@phosphor-icons/react";

// Context
import { timerContext } from "./Timer";

function AlarmSide() {
    // Redux & useState, useRef
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const [isFlow, setIsFlow] = useState(false)
    const audioRef = useRef(null);

    // Style
    const style = useStyle();

    // Context
    const {
        setTime, 
        workDuration, 
        breakDuration, 
        isWorkSession, 
        isAlarm,
        setIsAlarm,
        setIsWorkSession, 
    } = useContext(timerContext)

    // Functions
    const clickHandler = (type) => {
        switch (type) {
            case "break":
                setIsWorkSession(!isWorkSession);
                setTime(breakDuration);
                setIsAlarm(!isAlarm);
                break;
            case "work":
                setIsWorkSession(!isWorkSession);
                setTime(workDuration);
                setIsAlarm(!isAlarm);
                break;
            case "flow":
                setIsFlow(true);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        const audio = audioRef.current;

        const playAudio = async (src) => {
            if (audio) {
                audio.src = src;
                try {
                    await audio.play();
                } catch (error) {
                    console.error(error);
                }
            }
        };

        const fetchAudioSrc = async (type) => {
            const links = {
                work: "https://dl.dropboxusercontent.com/scl/fi/800fr3ga1dm7zjf51lu1d/Twinkle.mp3?rlkey=hg0pk2bibnteajpmw5xeby4n2",
                break: "https://dl.dropboxusercontent.com/scl/fi/djgiqq23ynl17gx1zmvaw/Ding.mp3?rlkey=tctfwhpq0zszpehtsvjuy9ld1",
            };
            return links[type];
        };

        const type = isWorkSession ? "work" : "break";
        fetchAudioSrc(type).then(playAudio);

        return () => {
            if (audio) {
                audio.pause();
                audio.src = ""; 
            }
        };
    }, [isWorkSession]);

    return (
        <Stack
            direction="column" gap="20px"
        >
            {isWorkSession ?
                !isFlow &&
                    <audio ref={audioRef} />
            :
                <audio ref={audioRef} />
            }
            <Stack
                direction="row" justifyContent="center" 
                gap="20px"
            >
                {isWorkSession ? 
                    !isFlow ?
                        <>
                            <Stack
                                className={style.button}
                                onClick={() => clickHandler("break")}
                            >
                                <Coffee size={80} color={isDarkMode ? "#fff" : "#020202"} />
                                <Stack>Break</Stack>
                            </Stack>
                            <Stack
                                className={style.button}
                                onClick={() => clickHandler("flow")}
                            >
                                <Brain size={80} color={isDarkMode ? "#fff" : "#020202"} />
                                <Stack>Continue</Stack>
                            </Stack>
                        </>
                    :
                        <Stack
                            className={style.button}
                            onClick={() => clickHandler("break")}
                        >
                            <Coffee size={80} color={isDarkMode ? "#fff" : "#020202"} />
                            <Stack>Break</Stack>
                        </Stack>
                :
                    <Stack
                        className={style.button}
                        onClick={() => clickHandler("work")}
                    >
                        <Books size={80} color={isDarkMode ? "#fff" : "#020202"} />
                        <Stack>Start</Stack>
                    </Stack>
                }
            </Stack>
        </Stack>
    )
}

export default AlarmSide;