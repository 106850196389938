import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Libraries
import Draggable from "react-draggable";

// MUI & Style
import { Stack } from "@mui/material";

// Constants
import { quotes } from "../../../Constants";

function Quotes() {
    // Redux & useState
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const isQuotesOpen = useSelector((state) => state.tools.isQuotesOpen)
    const [currentQuote, setCurrentQuote] = useState(0)

    // Functions
    useEffect(() => {
        setCurrentQuote(Math.floor(Math.random() * quotes.length))
    }, [isQuotesOpen])

    if (isQuotesOpen) {
        return (
            <Draggable>
                <Stack
                    width="500px" height="120px"
                    padding="20px" borderRadius="16px"
                    direction="column" alignItems="center"
                    justifyContent="center" gap="15px"
                    position="absolute" right="15px" bottom="15px"
                    zIndex="2"
                    sx={{
                        background: isDarkMode ? "#020202" : "#fff"
                    }}
                >
                    <Stack
                        fontSize="18px" fontWeight="700"
                        width="100%" textAlign="left"
                    >
                        {quotes[currentQuote][0]}
                    </Stack>
                    <Stack 
                        fontSize="16px" fontWeight="600" 
                        width="100%" textAlign="right"
                    >
                        {quotes[currentQuote][1]}
                    </Stack>
                </Stack>
            </Draggable>
        );
    }
}

export default Quotes;