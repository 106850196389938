import { useState } from "react";
import { useDispatch } from "react-redux";
import { deleteNote } from "../../../Redux/toolsSlice";

// Libraries
import Draggable from "react-draggable";

// MUI & Style
import { useStyle } from "../../../Atoms/Style";
import { Stack } from "@mui/material";

// Icons
import { TrashSimple } from "@phosphor-icons/react";

function Notes({id}) {
    // Redux & useState
    const dispatch = useDispatch()
    const [note, setNote] = useState("")
    
    // Style
    const style = useStyle()

    // Functions
    const clickHandler = () => {
        dispatch(deleteNote(id))
    }

    return (
        <Draggable cancel=".noDrag">
            <Stack
                position="absolute" left="400px" top="200px"
                zIndex="2"
            >
                <Stack
                    width="200px" height="150px" position="relative"
                    direction="row" alignItems="center"
                    justifyContent="center" borderRadius="16px"
                    sx={{
                        background: "#FFBE0B"
                    }}
                >
                    <textarea placeholder="Note" className={`${style.textarea} noDrag`} rows="4" cols="15" value={note} onChange={e => setNote(e.target.value)} />
                    <Stack
                        position="absolute" bottom="10px" right="10px"
                        onClick={clickHandler} className="noDrag"
                    >
                        <TrashSimple size={20} cursor="pointer" color="#020202" />
                    </Stack>
                </Stack>
            </Stack>
        </Draggable>
    );
}

export default Notes;