import { Stack } from "@mui/material";

function SignIn() {
    return (
        <Stack>
            
        </Stack>
    );
}

export default SignIn;