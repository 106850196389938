import { createSlice } from '@reduxjs/toolkit';

const vibeSlice = createSlice({
    name: 'vibeSlice',
    initialState: {
        isDarkMode: false,
        isBackgroundImage: false,
        background: "linear-gradient(0deg, #3D05DD 0%, #FCB0F3 100%)",
    },
    reducers: {
        setIsDarkMode: (state, action) => {
            console.log(action.payload)
            state.isDarkMode = action.payload
        },
        setIsBackgroundImage: (state, action) => {
            state.isBackgroundImage = action.payload
        },
        setBackground: (state, action) => {
            state.background = action.payload
        },
    },
});

export const { setIsDarkMode, setIsBackgroundImage, setBackground } = vibeSlice.actions;
export default vibeSlice.reducer;