import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Libraries
import { v4 as uuidv4 } from 'uuid';
import Draggable from "react-draggable";

// MUI & Style
import { useStyle } from "../../../Atoms/Style";
import { Stack } from "@mui/material";

// Icons
import { Check, Circle, Plus, TrashSimple } from "@phosphor-icons/react";

function Tasks() {
    // useState & Redux
    const isTasksOpen = useSelector((state) => state.tools.isTasksOpen)
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const [tasks, setTasks] = useState([
        {
            key: uuidv4(),
            content: "",
            priority: "high",
        },
    ])
    const [doneTasks, setDoneTasks] = useState([])
    const [filter, setFilter] = useState("all")
    const [filteredTasks, setFilteredTasks] = useState([])

    // Style 
    const style = useStyle(isDarkMode)

    // Functions
    const addTask = () => {
        setTasks((prevItems) => [...prevItems, {
            key: uuidv4(),
            content: "",
            priority: filter === "all" ? "high" : filter,
        }]);
    }

    const clickHandler = (type, content) => {
        switch (type) {
            case "reopen":
                setDoneTasks((prevItems) => prevItems.filter((key) => key !== content))
                break;
            case "done":
                setDoneTasks((prevItems) => [...prevItems, content])
                break;
            case "delete":
                setTasks((prevItems) => prevItems.filter((item) => item.key !== content))
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (filter !== "all") {
            setFilteredTasks(tasks.filter(task => task.priority === filter))
        } else {
            setFilteredTasks(tasks)
        }
    }, [tasks, filter])

    if (isTasksOpen) {
        return (
            <Draggable cancel=".noDrag">
                <Stack
                    width="400px" height="fit-content"
                    padding="20px" borderRadius="16px"
                    direction="column" gap="5px"
                    position="absolute" left="400px"
                    zIndex="2"
                    sx={{
                        background: isDarkMode ? "#020202" : "#fff"
                    }}
                >
                    <Stack
                        width="100%" height="fit-content"
                        direction="row" alignItems="center"
                        justifyContent="space-between"
                    >
                        <Stack fontSize="24px" fontWeight="700">Tasks</Stack>
                        <Stack>
                            <select 
                                className={`${style.select} ${style.filter} noDrag`} 
                                value={filter} onChange={(e) => setFilter(e.target.value)}
                            >
                                <option value="all">All</option>
                                <option value="high">High</option>
                                <option value="medium">Medium</option>
                                <option value="low">Low</option>
                            </select>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column" gap="5px"
                    >
                        {filteredTasks.map((task) => {
                            return (
                                <Stack
                                    width="100%" height="35px"
                                    border="1px solid"
                                    borderColor={isDarkMode ? "#fff" : "#020202"}
                                    borderRadius="8px" padding="10px"
                                    direction="row" alignItems="center"
                                    justifyContent="space-between"
                                    key={task.key}
                                    className="noDrag"
                                >
                                    <Stack
                                        direction="row" alignItems="center" gap="10px"
                                    >
                                        {doneTasks.includes(task.key) ? (
                                            <Check 
                                                size={18} 
                                                cursor="pointer"
                                                onClick={() => clickHandler("reopen", task.key)}
                                            />
                                        ) : (
                                            <Circle
                                                size={18}
                                                cursor="pointer"
                                                onClick={() => clickHandler("done", task.key)}
                                            />
                                        )}
                                        <input 
                                            className={`${style.input} ${style.task}`}
                                            value={task.content} 
                                            onChange={(item) => 
                                                setTasks(tasks.map(element => element.key === task.key ? { ...element, content: item.target.value } : element))
                                            }
                                        />
                                    </Stack>
                                    <Stack
                                        direction="row" alignItems="center" gap="10px"
                                    >
                                        <Stack
                                            direction="row" alignItems="center"
                                            border="1px solid" padding="0px 7px"
                                            borderColor={isDarkMode ? "#fff" : "#020202"}
                                            gap="7px" borderRadius="8px"
                                        >
                                            <Stack width="10px" height="10px" borderRadius="50%" sx={{background: task.priority === "high" ? "#ff0000" : task.priority === "medium" ? "#FFD23F" : "#3E885B"}}></Stack>
                                            <select className={`${style.select} ${style.priority}`}
                                                value={task.priority}
                                                onChange={(item) => {
                                                    setTasks(tasks.map(element => element.key === task.key ? { ...element, priority: item.target.value } : element))
                                                }}
                                            >
                                                <option value="high">High</option>
                                                <option value="medium">Medium</option>
                                                <option value="low">Low</option>
                                            </select>
                                        </Stack>
                                        <TrashSimple 
                                            size={20} cursor="pointer" 
                                            onClick={() => clickHandler("delete", task.key)} 
                                        />
                                    </Stack>
                                </Stack>
                            )
                        })}
                    </Stack>
                    <Stack
                        width="100%" height="35px"
                        direction="row" alignItems="center"
                        justifyContent="center" borderRadius="8px"
                        sx={{
                            background: "#8D99AE", cursor: "pointer",
                        }}
                        onClick={addTask}
                        className="noDrag"
                    >
                        <Plus size={24} />
                    </Stack>
                </Stack>
            </Draggable>
        );
    }
}

export default Tasks;