import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsDarkMode } from "../../../Redux/vibeSlice";
import { setIsPerformanceOpen } from "../../../Redux/userSlice";

// MUI & Style
import { Stack } from "@mui/material";

// Icons
import { ArrowsIn, CornersOut, Fire, Moon, Sun } from "@phosphor-icons/react";

function Control({ elementRef }) {
    // Redux & useState
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode);
    const isPerformanceOpen = useSelector((state) => state.user.isPerformanceOpen)
    const dispatch = useDispatch();
    const [isFullScreen, setIsFullScreen] = useState(false)

    // Functions    
    const fullScreenHandler = () => {
        if (isFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if (elementRef.current.requestFullscreen) {
                elementRef.current.requestFullscreen();
            } else if (elementRef.current.mozRequestFullScreen) {
                elementRef.current.mozRequestFullScreen();
            } else if (elementRef.current.webkitRequestFullscreen) {
                elementRef.current.webkitRequestFullscreen();
            } else if (elementRef.current.msRequestFullscreen) {
                elementRef.current.msRequestFullscreen();
            }
        }
    }

    useEffect(() => {
        const checkFullScreen = () => {
            const isFullScreen = !!(
                document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||   
                document.msFullscreenElement       
            );
            setIsFullScreen(isFullScreen);
        };
      
        document.addEventListener('fullscreenchange', checkFullScreen);
        document.addEventListener('webkitfullscreenchange', checkFullScreen);
        document.addEventListener('mozfullscreenchange', checkFullScreen);
        document.addEventListener('MSFullscreenChange', checkFullScreen);
      
        checkFullScreen();
      
        return () => {
            document.removeEventListener('fullscreenchange', checkFullScreen);
            document.removeEventListener('webkitfullscreenchange', checkFullScreen);
            document.removeEventListener('mozfullscreenchange', checkFullScreen);
            document.removeEventListener('MSFullscreenChange', checkFullScreen);
        };
    }, []);

    return (
        <Stack
            width="180px"
            height="35px"
            position="absolute"
            bottom="15px"
            left="50%"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              transform: "translate(-50%, 0%)",
            }}
            zIndex="1"
        >
          <Stack
              width="33%"
              height="100%"
              borderRadius="1000px 0 0 1000px"
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ background: "rgba(255, 255, 255, 0.5)", cursor: "pointer" }}
              onClick={() => dispatch(setIsDarkMode(!isDarkMode))}
          >
              {isDarkMode ? <Sun size={24} color={isDarkMode ? "#020202" : "#fff"} /> : <Moon size={24} color={isDarkMode ? "#020202" : "#fff"} />}
          </Stack>
          <Stack
              width="33%"
              height="100%"
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ background: "rgba(255, 255, 255, 0.5)", cursor: "pointer" }}
              onClick={() => dispatch(setIsPerformanceOpen(!isPerformanceOpen))}
          >
              <Fire size={24} color={isDarkMode ? "#020202" : "#fff"} />
          </Stack>
          <Stack
              width="33%"
              height="100%"
              borderRadius="0 1000px 1000px 0"
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{ background: "rgba(255, 255, 255, 0.5)", cursor: "pointer" }}
              onClick={fullScreenHandler}
          >
              {isFullScreen ? <ArrowsIn size={24} color={isDarkMode ? "#020202" : "#fff"} /> : <CornersOut size={24} color={isDarkMode ? "#020202" : "#fff"} />}
          </Stack>
        </Stack>
    );
}

export default Control;