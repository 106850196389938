import { createSlice } from '@reduxjs/toolkit';

const toolsSlice = createSlice({
    name: 'toolsSlice',
    initialState: {
        isTasksOpen: false,
        isMusicOpen: false,
        isQuotesOpen: false,
        isMaterialsOpen: false,
        notes: [],
    },
    reducers: {
        setIsTasksOpen: (state, action) => {
            state.isTasksOpen = action.payload
        },
        setIsMusicOpen: (state, action) => {
            state.isMusicOpen = action.payload
        },
        setIsQuotesOpen: (state, action) => {
            state.isQuotesOpen = action.payload
        },
        setIsMaterialsOpen: (state, action) => {
            state.isMaterialsOpen = action.payload
        },
        addNote: (state, action) => {
            state.notes.push(action.payload)
        },
        deleteNote: (state, action) => {
            state.notes = state.notes.filter((item) => item !== action.payload)
        },
    },
});

export const { setIsTasksOpen, setIsMusicOpen, setIsQuotesOpen, setIsMaterialsOpen, addNote, deleteNote } = toolsSlice.actions;
export default toolsSlice.reducer;