// MUI & Style
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

function Performance() {
    const isDarkMode = useSelector((state) => state.vibe.isDarkMode)
    const isPerformanceOpen = useSelector((state) => state.user.isPerformanceOpen)

    if (isPerformanceOpen) {
        return (
            <Stack
                width="350px" height="150px"
                position="absolute" left="50%" bottom="65px"
                borderRadius="16px" direction="row"
                alignItems="center" justifyContent="center"
                sx={{
                    background: isDarkMode ? "#020202" : "#fff",
                    transform: "translate(-50%, 0%)",
                }}
            >
                <Stack
                    fontSize="32px"
                    fontWeight="700"
                >Coming Soon!</Stack>
            </Stack>
        );
    }
}

export default Performance;