import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
    card: {
        padding:"15px", borderRadius:"16px",
        background: isDarkMode => isDarkMode ? "#020202" : "#fff",
    },
    input: {
        width: "100%", height: "100%",
        border: "none", outline: "none",
        background: "none", color: isDarkMode => isDarkMode ? "#fff" : "#020202", 
    },
    task: {
        fontFamily: "nunito",
        fontSize: "16px", fontWeight: "500"
    },
    range: {
        appearance: "none", 
        width: "75%", height: "10px",
        border: "1px solid",
        borderColor: isDarkMode => isDarkMode ? "#fff" : "#020202", 
        background: isDarkMode => isDarkMode ? "#020202" : "#fff", 
        borderRadius: "1000px",
        '&::-webkit-slider-thumb': {
            appearance: 'none',
            width: '8px', height: '8px',
            borderRadius: '50%', background: "#8D99AE",
            cursor: 'pointer',
        },
    },
    feedback: {
        fontSize: "16px", padding: "10px",
        border: "2px solid #020202",
        borderRadius: "12px",
    },
    button: {
        padding: "5px", display: "flex", cursor: "pointer",
        alignItems: "center", justifyContent: "center", background: isDarkMode => isDarkMode ? "#020202" : "#fff"
    },
    select: {
        fontFamily: "nunito", fontWeight: "500",
        appearance: "none", background: "transparent",
        lineHeight: "1.5", textAlign: "center", outline: "none"
    },
    filter: {
        width: "65px", height: "25px",
        border: "1px solid", borderRadius: "8px",
        borderColor: isDarkMode => isDarkMode ? "#fff" : "#020202",
        color: isDarkMode => isDarkMode ? "#fff" : "#020202",
    },
    priority: {
        border: "none", color: isDarkMode => isDarkMode ? "#fff" : "#020202",
    },
    sendFeedback: {
        display: "flex", cursor: "pointer",
        alignItems: "center", justifyContent: "center",
        border: "2px solid #020202", borderRadius: "10px",
        padding: "10px", fontSize: "16px", background: "none"
    },
    textarea: {
        fontFamily: "nunito", 
        fontWeight: "500", fontSize: "14px",
        background: "transparent", resize: "none",
        border: "none", outline: "none", 
    }
});